import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import * as moment from "moment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { UserCompany } from '../models/user_company.model';
import { tap, map, retry, catchError } from 'rxjs/operators';
import { Member } from '../models/member.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.apiserver;
  userSession : User;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private httpClient: HttpClient) { 
    //getting current user
    this.user.subscribe(data => {
      this.userSession = data;
    });
  }
  
  private waitingSession = new BehaviorSubject(false); 
  waiting : Observable<boolean> = this.waitingSession.asObservable();

  private session = new BehaviorSubject(new User()); 
  user : Observable<User> = this.session.asObservable();

  private companySession = new BehaviorSubject(new UserCompany()); 
  company : Observable<UserCompany> = this.companySession.asObservable();

  updatedDataSelection(data: User) {
    this.session.next(data);
  }  

  updatedwaitingSession(data: boolean) {
    this.waitingSession.next(data);
  }  

  updateNewCompany(data: UserCompany){
    this.companySession.next(data);
  }

  getMemberByUser(id?: number, page?: number): Observable<Member> {
    let url = `${this.baseUrl}/user?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/user/` + id;
    } 

    return this.httpClient.get<Member>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  validateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/login`; debugger;

    return this.httpClient.post(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  public isLoggedIn() {
    var yes =moment().isBefore(this.getExpiration())
    return yes;
  }

  getExpiration() {
    
    // this.user.subscribe(data =>{
    //   const expiration = data.tokenexpire;
    //   //const expiresAt = JSON.parse(expiration);
    //   return (expiration);
    // });    
    
    const expiration = this.userSession.tokenexpire;
    return (expiration);
  }

  getLoggedUser(): Observable<boolean>{ 
    let url = `${this.baseUrl}/loggeduser`; 
    var data = {};

    this.user.subscribe(res =>{
      data = {
        token : res.token 
      }
    });  

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  endSession(): Observable<boolean>{
    let url = `${this.baseUrl}/logout`;     
    
    var data = {};

    this.user.subscribe(res =>{
      data = {
        token : res.token 
      }
    }); 

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  saveUser(data: any): Observable<User> {
    let url = `${this.baseUrl}/register`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/update/` + data['id'];
    }


    return this.httpClient.post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  saveUserPicture(data){
    let url = `${this.baseUrl}` + "/profilepic";    

    
    return this.httpClient.post(url, data)
      .pipe(
        retry(0),
        catchError(this.handleError)
      )
  } 


  getCheckinQr(company, branch){
    let url = `${this.baseUrl}/checkinbyqr/`+company + `/`+branch;

    return this.httpClient.get(url, { responseType: 'text' });

  }

  getCheckoutQr(company, branch){
    let url = `${this.baseUrl}/checkoutbyqr/`+company + `/`+branch;

    return this.httpClient.get(url, { responseType: 'text' });

  }
  

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }



}
