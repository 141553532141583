import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { MemberService } from '../../../shared/services/member.service';
import { Member } from '../../../shared/models/member.model';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MembershipCard } from '../../../shared/models/membershipcard.model';

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  currentMember: Member;
  editId: number = 0;
  lastMembership : MembershipCard;

  membershipcardForm : FormGroup;

  statusList: SelectItem[];

  constructor(
    private memberService: MemberService, 
    private route: ActivatedRoute, 
    private messageService: MessageService,
    private fb: FormBuilder) {

    this.statusList = [
      { label: 'Status', value: null },
      { label: 'Issued', value: 1 },
      { label: 'Blocked', value: 2 },
      { label: 'Re Issued', value: 3 },

    ];

  }

  ngOnInit() {
    if (this.route.snapshot.params.id) {
      this.editId = this.route.snapshot.params.id;
      this.getMember(this.editId);
    }

    this.buildForm();
    this.getLastNo();
  }

  getLastNo(){
    this.memberService.getLastMembershipId().subscribe(res=>{
      this.lastMembership = res['data'];
    })
  }

  getMember(id) {
    this.memberService.getMember(id).subscribe(res => {
      this.currentMember = res['data'];

      this.membershipcardForm.patchValue({
        id : this.currentMember['membership'][0]['id'],
        membership_card_id : this.currentMember['membership'][0]['membership_card_id'],
        status : this.currentMember['membership'][0]['status']
      });
    });
  }

  addMembershipCard(event){
    if (this.membershipcardForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields!' });
      return;
    }

    this.memberService.saveMembershipCard(this.membershipcardForm.value).subscribe(res => {
      
      if(res['error']){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res['error']});
      }else{
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Membership details saved successfully!' });
      }
      
      

      // this.router.navigate(['/member/' + res['data']['id']]);

    }, error=>{
      

    });
  }

  buildForm(){
    this.membershipcardForm = this.fb.group({
      id: [''],
      company_id:['0'],
      membership_card_id: ['0', Validators.required],
      member_id: [this.editId, Validators.required],
      status: ['1']
    });   
  }


}
