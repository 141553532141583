import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';


// import { LoginComponent } from './views/login/login.component';


export const routes: Routes = [
  {
    path :'',
    loadChildren : './modules/base/base.module#BaseModule'
   },
  {
    path :'login',
    loadChildren : './modules/login/login.module#LoginModule'
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   data: {
  //     title: 'Register Page'
  //   }
  // },  
  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
