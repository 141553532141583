import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  isWaiting : boolean = false;  
  userSession : User;

  msgs: Message[] = [];

  constructor( private authService: AuthService,  private _router: Router) { }

  ngOnInit() {
    this.isWaiting = false;
    this.msgs = [];
    
    //getting current user
    this.authService.user.subscribe(data => {
      this.userSession = data;
    });
    
  }

  validateUser() {
    this.isWaiting = true;

    var credentials = {
      "email": this.username,
      "password": this.password
    }

    this.authService.validateUser(credentials).subscribe(res => {
      
      this.isWaiting = false;

      // update user session
      this.authService.updatedDataSelection({
        id:res["id"], 
        name:res["name"], 
        email:res["email"], 
        password:"", 
        image:res["image"], 
        role:res["role"],
        contact:res["contact"], 
        token :res["access_token"], 
        tokenexpire: moment().add(JSON.parse(res["expires_in"]), 'second')  
      });

      this._router.navigate(['/dashboard']);
            
    }, err =>{
      this.isWaiting = false;
      this.msgs = [];
      this.msgs.push({severity:'error', detail:'Sorry, username or password is incorrect!'});
    });
  }

}
