import { Component, OnInit } from '@angular/core';
import { Member } from '../../../shared/models/member.model';
import { MemberService } from '../../../shared/services/member.service';
import { environment } from '../../../../environments/environment';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-rmember',
  templateUrl: './rmember.component.html',
  styleUrls: ['./rmember.component.scss']
})
export class RmemberComponent implements OnInit {

  newMemberForm: FormGroup;
  allMemberList : Member[] = [];
  notificationList :Member[] = [];
  fileSelectForm: FormGroup;
   editId: number = 0;
   submitted: boolean;
   currentMember : Member;

  constructor(private memberService: MemberService,
    private messageService: MessageService,
    private fb: FormBuilder,) { }

  ngOnInit() {
    this.getAllMembers();
  }

  // getAllMembers(){
  //   this.memberService.getMember().subscribe(res =>{
  //     this.allMemberList = res['data'];
  //   });
  // }

//   forEach(data:any){
//     var day = data.birthday.split("/")
//     var currentdate = new Date().getFullYear();
//     var birthdayDate = new Date(currentdate, day[0] , day[0])
//     var now = new Date().valueOf();
//     if (birthdayDate.valueOf() < now){ 
//         birthdayDate.setFullYear(currentdate)
//     }
//     data.fromNow = birthdayDate.valueOf() - now;
// }

getAllMembers(){
  this.memberService.getNotification().subscribe(res =>{
    this.allMemberList = res['data'];
  });
 
}

getProfilePicLink(name?: string) {
  if (name) {
    return environment.imageServer + name + "?" + Math.random();
  } else {
    return environment.imageServer + "default.jpg?";
  }
}


}




