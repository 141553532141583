export class UserCompany{
    public id : number
    public user_id : number
    public companyname : string
    public address : string
    public email : string
    public contact : string
    public logo : string
    public website: string
    public facebook : string    
    public status  : string
}