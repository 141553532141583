import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { tap, map, retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Member } from '../models/member.model';
import { Payment } from '../models/payment.model';
import { MemberPackage } from '../models/memberPackage.model';
import { environment } from '../../../environments/environment';
import { MemberAttribute } from '../models/memberAttribute.model';
import { AttribTypes } from '../models/attribTypes.model';
import { MembershipCard } from '../models/membershipcard.model';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  
  baseUrl = environment.apiserver;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private httpClient: HttpClient) { }

  saveMember(data: any): Observable<Member> {
    let url = `${this.baseUrl}/member/create`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/member/update/` + data['id'];
    }


    return this.httpClient.post<Member>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  saveCustomerPicture(data){
    let url = `${this.baseUrl}` + "/member/profilepic";    

    
    return this.httpClient.post(url, data)
      .pipe(
        retry(0),
        catchError(this.handleError)
      )
  } 

  
  searchMembers(data: any): Observable<Member[]>{
    let url = `${this.baseUrl}/member/filter`; debugger;
    
    return this.httpClient.post<Member[]>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  
  getMemberByName(value : string): Observable<Member[]> {
    let url = `${this.baseUrl}/member/search/byname?query=`+ value;

    return this.httpClient.get<Member[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getMyQr(value : string){
    let url = `${this.baseUrl}/qrcode`;

    return this.httpClient.get(url, { responseType: 'text' });

  }

  getMemberQr(id){
    let url = `${this.baseUrl}/qrcode/`+id;

    return this.httpClient.get(url, { responseType: 'text' });

  }



  getMember(id?: number, page?: number): Observable<Member[]> {
    let url = `${this.baseUrl}/member?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/member/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/member?page=` + page
    }

    return this.httpClient.get<Member[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getMemberByMembershipId(id?: number, page?: number): Observable<Member[]> {
    let url = `${this.baseUrl}/member/bymembership`;

    if (id > 0) {
      url = `${this.baseUrl}/member/bymembership/` + id;
    }

    return this.httpClient.get<Member[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getMemberPackage(id?: number, page?: number): Observable<MemberPackage[]> {
    let url = `${this.baseUrl}/member_package?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/member_package/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/member_package?page=` + page
    }

    return this.httpClient.get<MemberPackage[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }
  getNotification(id?: number, page?: number){
    let url = `${this.baseUrl}/notification?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/notification/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/notification?page=` + page
    }

    return this.httpClient.get<Notification[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  saveMembershipCard(data: any): Observable<Member> {
    let url = `${this.baseUrl}/membershipcard/create`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/membershipcard/update/` + data['id'];
    }


    return this.httpClient.post<Member>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }


  saveMemberAttribute(data: any): Observable<MemberAttribute> {
    let url = `${this.baseUrl}/memberattribute/create`; debugger;
    if (data['id'] >0) {
      url = `${this.baseUrl}/memberattribute/update/` + data['id'];
    }


    return this.httpClient.post<MemberAttribute>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  
  getMemberAttribute(id?: number, page?: number): Observable<MemberAttribute[]> {
    let url = `${this.baseUrl}/member_attribute?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/member_attribute/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/member_attribute?page=` + page
    }

    return this.httpClient.get<MemberAttribute[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getAttributeList(id?: number, page?: number): Observable<AttribTypes[]> {
    let url = `${this.baseUrl}/attributetypes?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/attributetypes/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/attributetypes?page=` + page
    }

    return this.httpClient.get<AttribTypes[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getLastMembershipId(id?: number, page?: number): Observable<MembershipCard> {
    let url = `${this.baseUrl}/membershipcard/last/id`;

    return this.httpClient.get<MembershipCard>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
 

}