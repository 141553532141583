import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { tap, map, retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Payment } from '../models/payment.model';
import { PaymentType } from '../models/paymentTypes.model';
import { environment } from '../../../environments/environment';
import { PaymentDues } from '../models/paymentDues.model';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  savePayment: any;
  
  baseUrl = environment.apiserver;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }

  getPaymentType(id?: number, page?: number): Observable<PaymentType[]> {
    let url = `${this.baseUrl}/paymenttype?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/paymenttype/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/paymenttype?page=` + page
    }

    return this.httpClient.get<PaymentType[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getPaymentByMember(id?: number, page?: number): Observable<Payment[]> {
    let url = `${this.baseUrl}/payment?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/paymentbymember/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/payment?page=` + page
    }

    return this.httpClient.get<Payment[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getPaymentDuesByMember(id?: number, page?: number): Observable<PaymentDues[]> {
    let url = `${this.baseUrl}/allpaymentdues?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/paymentdues/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/allpaymentdues?page=` + page
    }

    return this.httpClient.get<PaymentDues[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  
  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }



  addPayment(data: any): Observable<Payment> {
    let url = `${this.baseUrl}/payment/create`; debugger;
    if (data['id']> 0) {
      url = `${this.baseUrl}/payment/update/` + data['id'];
    }
    
    return this.httpClient.post<Payment>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
}
}

