import { Component, OnInit } from '@angular/core';
import { MemberService } from '../../../shared/services/member.service';
import { Member } from '../../../shared/models/member.model';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PaymentService } from '../../../shared/services/payment.service';
import { formatDate } from '@angular/common';
import { MessageService, DialogService } from 'primeng/api';
import { Payment } from '../../../shared/models/payment.model';
import { PageMeta } from '../../../shared/core/page_meta';
import { MemberPackage } from '../../../shared/models/memberPackage.model';
import { PaymentType } from '../../../shared/core/PaymentTypes.enum';
import { environment } from '../../../../environments/environment';
import { PaymentDues } from '../../../shared/models/paymentDues.model';
import { InvoiceComponent } from '../invoice/invoice.component';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-pslip',
  templateUrl: './pslip.component.html',
  styleUrls: ['./pslip.component.scss'],
  providers: [DialogService]
})
export class PslipComponent implements OnInit {

  // member: any = {
  //   value: []
  // }
  //  onChangeMemberDetails(event, id) {
  //   console.log(this.member.value[id]);
  // }
  newMemberForm: FormGroup;
  newMemberForm1: FormGroup;
  value: Date;

  allMemberList: Member[] = [];

  memberOptions: any[] = [];
  currentMember: Member;
  route: any;
  editId: number = 0;
  submitted: boolean;
  formBuilder: any;
  group: any;

  memberPaymentList : Payment[] =[];
  metaPayment : PageMeta;

  totalDueAmount : number =0;
  
  memberPaymentDueList : PaymentDues[] =[];
  metaPaymentDues : PageMeta;

  filterOn : boolean  = false;

  currentPackage : MemberPackage;
  memberPackageList : MemberPackage[] = [];

  newPrintObj : Payment;
  printReady : boolean = false;


  constructor(
    private memberService: MemberService,
    private paymentService: PaymentService,
    private authService: AuthService,
    private fb: FormBuilder, 
    private messageService: MessageService,
    public dialogService: DialogService) {

  }

  ngOnInit() {
    this.buildForm();


  }

  
  // getMember(id) {
  //   this.memberService.getMember(id).subscribe(res => {
  //     this.currentMember = res['data'];

  //     this.newMemberForm.patchValue({
  //       id: this.currentMember['member_id']['id'],
  //       member_id: this.currentMember['member_id'][0]['member_id'],

  //     });

     
  //   });
  // }

  getMemberPayments(id?, page?){
    this.paymentService.getPaymentByMember(id, page).subscribe(res =>{
      this.memberPaymentList = res['data'];
      this.metaPayment = res['meta'];

      if(res['meta']['total']==0){
        
      }

    });
  }

  getMemberDuePayments(id?, page?){
    this.totalDueAmount = 0 ;
    this.paymentService.getPaymentDuesByMember(id, page).subscribe(res =>{
      this.memberPaymentDueList = res['data'];

      for(let due of this.memberPaymentDueList){
        this.totalDueAmount = (this.totalDueAmount) + parseFloat(due.due_amount);
      }

      this.newMemberForm.patchValue({
        amount_pending : this.totalDueAmount
      })
    });
  }

  getMemberByMembershipId(){ debugger;
    let id = this.newMemberForm.get('membership_card_id').value;

    this.memberService.getMemberByMembershipId(id).subscribe(res=>{
      this.currentMember = res['data'];
      this.getMemberPayments(this.currentMember['id']);
      this.getMemberDuePayments(this.currentMember['id']);

      this.newMemberForm.patchValue({
        member_id: this.currentMember['id'],
        name : this.currentMember['first_name'] + " " + this.currentMember['last_name'],
        branch_id: this.currentMember['memberBranches']['id'],
        membership_card_id: this.currentMember.membership[0].membership_card_id
      });

      this.memberPackageList = res['data']['memberPackages'];
      this.currentPackage = this.memberPackageList.find(x=>x.status==1);
    }, err =>{
      this.currentMember = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check membership no' });
    });
  }

  getMemberPackage(id?, page?){
    this.memberService.getMemberPackage(id, page).subscribe(res =>{
     
      this.memberPackageList = res['data'];
      this.currentPackage = this.memberPackageList.find(x=>x.status==1);
    });
  }

  addPayment(value: string) {

    if (this.newMemberForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields!' });
      return true;
    }

    this.authService.updatedwaitingSession(true);

    var defaultDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.newMemberForm.patchValue({
      payment_date : defaultDate,
      package_id : this.currentPackage.package.id,
      payment_type : PaymentType.Cash,
      status: 1
    })

    this.paymentService.addPayment(this.newMemberForm.value).subscribe(res => {
      var tempId = res['data']['id'];
      this.printReady = true;
      this.newPrintObj = res['data'];

      this.authService.updatedwaitingSession(false);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Payment details saved successfully!' });
      this.getMemberPayments(this.currentPackage.memberProfile.id);
      // this.router.navigate(['/member/' + res['data']['id']]);


    });
  }
  
  buildForm() {
    //form group for new customer
    this.newMemberForm = this.fb.group({
      id: [''],
      company_id: ['0'],
      member_id: ['0'],
      membership_card_id: ['0',Validators.required],
      package_id: ['0'],
      branch_id: ['0'],
      amount_paid: [''],
      amount_pending: ['0'],
      payment_date: [''],
      added_by: ['0'],
      payment_type: ['0',],
      remarks: [''],
      status: ['1'],
      
      payment_id: [''],
      valid_from: ['', Validators.required],
      valid_to: ['', Validators.required]

    });
  }

  getMemebrOnDropdownChange(event) { debugger;
    this.newMemberForm.patchValue({
      member_id : event.value
    })

    this.getMemberPayments(event.value);
   this.getMemberPackage(event.value);
  }

  search(event) {
    this.memberService.getMemberByName(event.query).subscribe(res => {
      this.memberOptions = [];
      this.allMemberList = res['data'];
      for (let member of res['data']) {
        this.memberOptions.push({
          label: member.first_name + " " + member.last_name,
          value: member.id
        })
      }
      //this.memberOptions = res['data'];
    });
  }

  resetCustomerField(event){
    this.currentPackage = null;
    this.memberPaymentList = null;
    this.newMemberForm.reset();
  }

  paginatePayment(event) {
    console.log(event);
    if(this.filterOn==true){
      //this.searchSales(event.page +1);
    }else{
      this.getMemberPayments(this.editId, event.page +1);
    }
    
    event.rows = 3;
  }
  

  getProfilePicLink(name?: string) {
    if (name) {
      return environment.imageServer + name;
    } else {
      return environment.imageServer + "default.jpg?";
    }
  }

  calculateTotal(event){
    
    this.newMemberForm.patchValue({
      amount_pending : (this.totalDueAmount>0)? (this.totalDueAmount - parseFloat(event.target.value)) : 0
    })
  }

  showReport() {

    const ref = this.dialogService.open(InvoiceComponent, {
      data: {
        obj: this.newPrintObj
      },
      header: 'Pay Slip',
      height: '500px',
      width: '300px'
    });

    ref.onClose.subscribe((data: any) => {

    });
  }

}

