import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PslipRoutingModule } from './pslip-routing.module';
import { PslipComponent } from './pslip.component';

import {MessageModule} from 'primeng/message';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { CardModule } from '../card/card.module';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [PslipComponent],
  imports: [
    CommonModule,
    PslipRoutingModule,
    MessagesModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    DropdownModule,
    CommonModule,
    TabViewModule,
    SplitButtonModule,
    FileUploadModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CardModule,
    ButtonModule,
    ToastModule,    
    CalendarModule,
    AutoCompleteModule,      
    PaginatorModule,
    InputTextareaModule,
    DynamicDialogModule
  ]
})
export class PslipModule { }
