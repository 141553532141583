import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RmemberComponent } from './rmember.component';


const routes: Routes = [{
  path : '',
  component : RmemberComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RmemberRoutingModule { }
