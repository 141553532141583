import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  printObject : any;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private _router: Router) { }

  ngOnInit() {
    this.printObject = this.config.data.obj;
  }

  print(){
    var printContents = document.getElementById('printDiv').innerHTML;
     var originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
     this._router.navigate(['/payment/details']);
  }

}
