interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Profile',
    url: '/admin/profile',
    icon: 'icon-user-follow',
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Main'
  },
  {
    name: 'Member ',
    url: '/member',
    icon: 'icon-layers',
    children: [{
      name: 'Register new',
      url: '/member/register',
      icon: 'icon-user-follow'
    },
    {
      name: 'All members',
      url: '/member/details',
      icon: 'icon-people'
    },
    {
      name: 'Member attendance',
      url: '/member/attendance',
      icon: 'icon-bell'
    },
    {
      name: 'Add attendance',
      url: '/member/addattendance',
      icon: 'icon-bell'
    },
    // {
    //   name: 'Access other member',
    //   url: '/accessothermember',
    //   icon: 'icon-user',
    // },
    // {
    //   name: 'Reservation',
    //   url: '/reservation',
    //   icon: 'icon-user',
    // },
    {
      name: 'Add Schedule',
      url: '/member/addschedule',
      icon: 'icon-note',
    }]

  },
  {
    name: 'Assets ',
    url: '/workdesk',
    icon: 'icon-user',
    children: [{
      name: 'Machine details',
      url: '/machinedetail',
      icon: 'icon-people'
    },
    {
      name: 'Add Assets',
      url: '/addassets',
      icon: 'icon-people'
    },
    // {
    //   name: 'Veiw Report',
    //   url: '/quantity',
    //   icon: 'icon-people'
    // },
    // {
    //   name: 'New audit',
    //   url: '/audit',
    //   icon: 'icon-people'
    // }
  ]

  },
  {
    name: 'Payment',
    url: '/payment',
    icon: 'icon-credit-card',
    children: [{
      name: 'Payment Slip',
      url: '/payment/pslip',
      icon: 'icon-credit-card'
    },
    {
      name: 'Due Payments',
      url: '/payment/dues',
      icon: 'icon-wallet'
    },
    {
      name: 'Payment Record',
      url: '/payment/details',
      icon: 'icon-list'
    }]

  },
  {
    name: 'Instructor ',
    url: '/sample',
    icon: 'icon-user',
    children: [{
      name: 'Add Instructor',
      url: '/newinstructor',
      icon: 'icon-people'
    },
    {
      name: 'Instructor Details',
      url: '/instructor/details',
      icon: 'icon-user-follow'
    },
    {
      name: 'Add attendance',
      url: '/intructor/attendance',
      icon: 'icon-user-follow'
    },
    {
      name: 'Intructor attendance',
      url: '/intructor/attendance/view',
      icon: 'icon-user-follow'
    }]
  },
  {
    name: 'Packages',
    url: '/packages',
    icon: 'icon-grid',
    children: [{
      name: 'Packages',
      url: '/packages',
      icon: 'icon-login'
    },
    {
      name: 'Add Package',
      url: '/packages/new',
      icon: 'icon-login'
    }]
  },
  {
    title: true,
    name: 'Extra'
  },
  {
    name: 'Settings',
    url: '/customer',
    icon: 'cui-cog',
    children: [{
      name: 'Branches',
      url: '/branches',
      icon: 'icon-login'
    }]
  },

];


export const navItems2: NavData[] = [
  {
    name: 'Profile',
    url: '/editor/profile',
    icon: 'icon-user-follow',
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Main'
  },
  {
    name: 'Member ',
    url: '/member',
    icon: 'icon-layers',
    children: [{
      name: 'Register new',
      url: '/member/register',
      icon: 'icon-user-follow'
    },
    {
      name: 'All members',
      url: '/member/details',
      icon: 'icon-people'
    },
    {
      name: 'Member attendance',
      url: '/member/attendance',
      icon: 'icon-bell'
    },
    {
      name: 'Add attendance',
      url: '/member/addattendance',
      icon: 'icon-bell'
    },
    // {
    //   name: 'Access other member',
    //   url: '/accessothermember',
    //   icon: 'icon-user',
    // },
    // {
    //   name: 'Reservation',
    //   url: '/reservation',
    //   icon: 'icon-user',
    // },
    {
      name: 'Add Schedule',
      url: '/member/addschedule',
      icon: 'icon-note',
    }]

  },


  {
    name: 'Assets ',
    url: '/workdesk',
    icon: 'icon-user',
    children: [{
      name: 'Machine details',
      url: '/machinedetail',
      icon: 'icon-people'
    },
    {
      name: 'Add Assets',
      url: '/addassets',
      icon: 'icon-people'
    },
    // {
    //   name: 'Veiw Report',
    //   url: '/quantity',
    //   icon: 'icon-people'
    // },
    // {
    //   name: 'New audit',
    //   url: '/audit',
    //   icon: 'icon-people'
    // }
  ]

  },


  {
    name: 'Payment',
    url: '/payment',
    icon: 'icon-credit-card',
    children: [{
      name: 'Payment Slip',
      url: '/payment/pslip',
      icon: 'icon-credit-card'
    },
    {
      name: 'Due Payments',
      url: '/payment/dues',
      icon: 'icon-wallet'
    },
    {
      name: 'Payment Record',
      url: '/payment/details',
      icon: 'icon-list'
    }]

  },

  // {
  //   name: 'Instructor ',
  //   url: '/sample',
  //   icon: 'icon-user',
  //   children: [{
  //     name: 'Add Instructor',
  //     url: '/newinstructor',
  //     icon: 'icon-people'
  //   },
  //   {
  //     name: 'Instructor Details',
  //     url: '/sample',
  //     icon: 'icon-user-follow'
  //   }]
  // },
  
  // {
  //   name : 'Schedule',
  //   url :'/schedule',
  //   icon : 'icon-user-follow'
  // },

  {
    name: 'Packages',
    url: '/packages',
    icon: 'icon-grid',
    children: [{
      name: 'Packages',
      url: '/packages',
      icon: 'icon-login'
    },
    {
      name: 'Add Package',
      url: '/packages/new',
      icon: 'icon-login'
    }]
  },
  {
    title: true,
    name: 'Extra'
  },
  {
    name: 'Settings',
    url: '/customer',
    icon: 'cui-cog',
    children: [{
      name: 'Branches',
      url: '/branches',
      icon: 'icon-login'
    }]
  },

];


export const navItems3: NavData[] = [
  
  // {
  //   name: 'Assets ',
  //   url: '/workdesk',
  //   icon: 'icon-user',
  //   children: [{
  //     name: 'Machine details',
  //     url: '/machinedetail',
  //     icon: 'icon-people'
  //   },
  //   {
  //     name: 'Add Asets',
  //     url: '/addassets',
  //     icon: 'icon-people'
  //   },
  //   {
  //     name: 'Veiw Report',
  //     url: '/quantity',
  //     icon: 'icon-people'
  //   },
  //   {
  //     name: 'New audit',
  //     url: '/audit',
  //     icon: 'icon-people'
  //   }]

  // },



  // {
  //   name: 'Instructor ',
  //   url: '/sample',
  //   icon: 'icon-user',
  //   children: [{
  //     name: 'Add Instructor',
  //     url: '/newinstructor',
  //     icon: 'icon-people'
  //   },
  //   {
  //     name: 'Instructor Details',
  //     url: '/sample',
  //     icon: 'icon-user-follow'
  //   }]
  // },
  
  // {
  //   name : 'Schedule',
  //   url :'/schedule',
  //   icon : 'icon-user-follow'
  // },
  {
    name: 'Profile',
    url: '/profile',
    icon: 'icon-user-follow'
  },
  {
    name: 'Packages',
    url: '/packages',
    icon: 'icon-grid'
  }
];
