import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RmemberRoutingModule } from './rmember-routing.module';
import { RmemberComponent } from './rmember.component';



@NgModule({
  declarations: [RmemberComponent],
  imports: [
    CommonModule,
    RmemberRoutingModule,
   
  ],
  exports:[RmemberComponent]
})
export class RmemberModule { }
